import { Injectable, NgZone } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthService } from '../services/auth/auth.service';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { Router } from '@angular/router';
import { AuthData } from '../models/auth-data';
import { AuthActions, AuthStateActions } from './auth.actions';
import { AuthError } from '../services/auth/auth-error';
import { ErrorReporter } from '../services/error-reporter';
import { HttpErrorResponse } from '@angular/common/http';

/**
 * Authentication state NgRx effects.
 */
@Injectable()
export class AuthEffects {
    /**
     * Initialize authentication.
     *
     * If user was already authenticated, send loginCompleted action.
     * If user was not authenticated, send loginInitialized action.
     */
    initAuth$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.initAuth),
            mergeMap(() =>
                from(this.auth.init()).pipe(
                    map((authData: AuthData) => {
                        return AuthStateActions.loginCompleted({
                            user: authData.user,
                        });
                    }),
                ),
            ),
            catchError(e => {
                const isUnauthenticated = e instanceof HttpErrorResponse && e.status === 401;
                if (e instanceof AuthError || isUnauthenticated) {
                    return of(AuthStateActions.loginFailed({ error: e.message }));
                } else {
                    this.errorReporter.reportError(e);
                    return of(AuthStateActions.loginFailed({ error: `Authentication error` }));
                }
            }),
        ),
    );

    constructor(
        private readonly actions$: Actions,
        private readonly auth: AuthService,
        private readonly router: Router,
        private readonly errorReporter: ErrorReporter,
        private readonly ngZone: NgZone,
    ) {}
}
