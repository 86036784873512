import { ModuleItem, ModuleList } from './types';

const AllModules: ModuleItem[] = [
    {
        id: 'aimarketing',
        name: 'Ai Marketing',
        href: '/aimarketing',
        isFavorite: false,
        icon: 'hdd',
    },
    {
        id: 'rfx',
        name: 'Ai Automatyzacja RFX',
        href: '/rfx',
        isFavorite: false,
        icon: 'bug',
    },
    {
        id: 'demandforecasting',
        name: 'Ai Prognozowanie Popytu',
        href: '/demandforecasting',
        isFavorite: true,
        icon: 'fund',
    },
];

export const AvailableModuleList: ModuleList[] = [
    {
        blockTitle: 'Ogólne',
        modules: [
            {
                name: 'Panel główny',
                href: '/dashboard',
                icon: 'desktop',
            },
            {
                name: 'Powiadomienia',
                href: '/',
                icon: 'notification',
            },
        ],
    },
    {
        blockTitle: 'Moduły',
        modules: [...AllModules],
    },
    // {
    //     blockTitle: 'Ulubione',
    //     modules: [...AllModules.filter(module => module.isFavorite)],
    // },
];

export const SupportAndSettingsModuleList: ModuleList[] = [
    {
        blockTitle: 'Administracja',
        modules: [
            {
                name: 'Wsparcie techniczne',
                href: '/support',
                icon: 'info-circle',
            },
            {
                name: 'Ustawienia',
                href: '/settings',
                icon: 'setting',
            },
        ],
    },
];

// export const DetailedModuleList: any[] = [
//     {
//         name: 'aiMarketingTitle',
//         description: 'aiMarketingDescription',
//         shortName: 'A1M',
//         icons: ['hdd', 'safetyCertificate', 'mail'],
//         isMine: true,
//         url: '/AIMarketing',
//         imgSrc: '/cover_aiMarketing.jpg',
//         backgroundColor: '#FFC833',
//         tag: 'MediaManager',
//         icon: 'hdd',
//     },
//     {
//         name: 'rfxAutomationTitle',
//         description: 'rfxAutomationDescription',
//         shortName: 'A1RFX',
//         icons: ['pieChart', 'safetyCertificate', 'snippets', 'bank'],
//         isMine: true,
//         url: '/RfxAgent',
//         imgSrc: '/cover_rfxAgent.jpg',
//         backgroundColor: '#59E1C0',
//         tag: 'LeadBuyer',
//         icon: 'bug',
//     },
//     {
//         name: 'demandForecastingTitle',
//         description: 'demandForecastingDescription',
//         shortName: 'A1PNow',
//         icons: ['shopping', 'api'],
//         isMine: true,
//         imgSrc: '/cover_demand_planning.jpg',
//         url: '/DemandForecasting',
//         backgroundColor: '#32A467',
//         tag: 'DemandPlanningManager',
//         icon: 'fund',
//     },
//     {
//         name: 'proposalAnalysisTitle',
//         description: 'proposalAnalysisDescription',
//         shortName: 'A1PA',
//         icons: ['snippets', 'hdd'],
//         imgSrc: '/cover_proposalAnalysis.jpg',
//         url: '/ProposalComparison',
//         backgroundColor: '#59E1C0',
//         tag: 'LeadBuyer',
//         icon: 'snippets',
//     },
// ];
