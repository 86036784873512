<div class="relative flex h-dvh w-full overflow-hidden">
    @if (isAuthenticated && !isExcludedPage) {
    <app-sidebar></app-sidebar>
    }

    <div class="main-page-content h-full basis-full">
        <app-top-navigation></app-top-navigation>
        @if(!isExcludedPage) {
        <div
            *ngIf="moduleData$ | async as moduleData"
            class="overflow-y-auto p-6"
            [ngClass]="moduleData.direction === 'vertical' || !moduleData.direction ? 'h-dvh' : 'h-[calc(100dvh-1.5rem-28px)]'"
        >
            <router-outlet></router-outlet>
        </div>
        } @else {
        <router-outlet></router-outlet>
        }
    </div>
</div>
