import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { SidebarBlockComponent } from './layout/sidebar/components/sidebar-block/sidebar-block.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import {
    BugOutline,
    DesktopOutline,
    DownOutline,
    FundOutline,
    HddOutline,
    InfoCircleOutline,
    LeftOutline,
    NotificationOutline,
    RightOutline,
    SearchOutline,
    SettingOutline,
    SnippetsOutline,
    UserOutline,
} from '@ant-design/icons-angular/icons';
import { NZ_ICONS, NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { UserProfileComponent } from './layout/sidebar/components/user-profile/user-profile.component';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { GeneralSearchComponent } from './layout/sidebar/components/general-search/general-search.component';
import { SidebarSectionComponent } from './layout/sidebar/components/sidebar-section/sidebar-section.component';
import { SidebarTopbarComponent } from './layout/sidebar/components/sidebar-topbar/sidebar-topbar.component';

const icons = [
    SearchOutline,
    HddOutline,
    BugOutline,
    FundOutline,
    SnippetsOutline,
    UserOutline,
    DownOutline,
    DesktopOutline,
    NotificationOutline,
    InfoCircleOutline,
    SettingOutline,
    LeftOutline,
    RightOutline,
];

@NgModule({
    declarations: [
        NotFoundPageComponent,
        SidebarComponent,
        SidebarBlockComponent,
        UserProfileComponent,
        GeneralSearchComponent,
        SidebarSectionComponent,
        SidebarSectionComponent,
        SidebarTopbarComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterLink,
        RouterLinkActive,

        // ng zorro modules
        NzLayoutModule,
        NzInputModule,
        NzIconModule,
        NzButtonModule,
        NzDropDownModule,
    ],
    exports: [SidebarComponent],
    providers: [{ provide: NZ_ICONS, useValue: icons }],
})
export class CoreModule {}
