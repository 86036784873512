import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-general-search',
    templateUrl: 'general-search.component.html',
})
export class GeneralSearchComponent {
    @Input() isExpanded: boolean;

    search() {
        // TODO: ask what we do about the search functionality inside the sidebar
    }
}
