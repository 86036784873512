import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { AuthService } from './core/services/auth/auth.service';
import { AppState } from './core/state';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AuthActions } from './core/state/auth.actions';
import { AuthSelectors } from './core/state/auth.selectors';
import { User } from './core/models/user';
import { TranslateService } from '@ngx-translate/core';
import { SidebarStateService } from './core/services/sidebar.service';
import { NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.css',
})
export class AppComponent implements OnInit {
    private title = inject(Title);
    private translation = inject(TranslateService);
    private readonly destroy = inject(DestroyRef);

    public user$: Observable<User>;
    public isAuthenticated: boolean;
    public moduleData$ = this.sidebarService.moduleData$;
    public isExcludedPage: boolean;

    constructor(
        private translate: TranslateService,
        private readonly store$: Store<AppState>,
        private readonly authService: AuthService,
        private readonly router: Router,
        private readonly sidebarService: SidebarStateService
    ) {
        this.translate.addLangs(['pl', 'en']);
        this.translate.setDefaultLang('pl');
        this.setLanguage();
    }

    ngOnInit(): void {
        this.router.events.pipe(takeUntilDestroyed(this.destroy)).subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.isExcludedPage = event.url === '/signin' || event.urlAfterRedirects === '/not-found';
                this.changeTitle(event.url);
            }
        });

        this.store$.dispatch(AuthActions.initAuth());
        this.user$ = this.store$.select(AuthSelectors.user);

        this.store$
            .select(AuthSelectors.isAuthenticated)
            .pipe(takeUntilDestroyed(this.destroy))
            .subscribe(is => {
                this.isAuthenticated = is;
            });
    }

    onLogout() {
        this.authService.logout().pipe(takeUntilDestroyed(this.destroy)).subscribe();
    }

    setLanguage() {
        const currentLang = localStorage.getItem('lang');
        const lang = currentLang ? currentLang : 'pl';
        this.translate.use(lang);
    }

    private changeTitle(url: string) {
        let title = '';
        const urlTree = this.router.parseUrl(url);
        let urlSegments = null;

        if (urlTree.root.children.primary) {
            urlSegments = urlTree.root.children.primary.segments?.map(segment => segment.path);

            if (urlSegments.length === 1) {
                title = urlSegments[0];
            }
            if (urlSegments.length >= 2) {
                title = urlSegments[1];
            }

            const key = 'routerTranslations.' + title;
            const translation = this.translation.instant(key);
            const isTranslationExist = translation !== key && translation !== '';

            this.title.setTitle(isTranslationExist ? this.translate.instant(key) + ' | AICONIC' : 'AICONIC');
        }
    }
}
