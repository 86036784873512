import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient } from '@angular/common/http';
import { metaReducers, reducers } from './core/state';
import { AppEffects } from './core/state/app.effects';
import { AuthEffects } from './core/state/auth.effects';
import { EnvironmentConfig } from './core/environment-config';
import { environment } from '../environments/environment';
import { SubdomainInterceptor } from './core/interceptors/subdomain.interceptor';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { CommonModule, registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { SidebarComponent } from './core/layout/sidebar/sidebar.component';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
registerLocaleData(en);

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
            },
        }),
        EffectsModule.forRoot([AppEffects, AuthEffects]),
        CoreModule,
        FormsModule,
    ],
    exports: [],
    providers: [
        { provide: EnvironmentConfig, useFactory: () => environment },
        { provide: HTTP_INTERCEPTORS, useClass: SubdomainInterceptor, multi: true },
        { provide: NZ_I18N, useValue: en_US },
        provideAnimationsAsync(),
        provideHttpClient(),
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
