import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ModuleItem } from '../layout/sidebar/types';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { TenantModules } from '../layout/sidebar/module-list';

@Injectable({
    providedIn: 'root',
})
export class SidebarStateService {
    // 553px = 316px (main sidebar) + 237px (secondary sidebar)
    // 316px = 79px (collapsed sidebar) + 237px (secondary sidebar)
    collapsedMainSidebarWidth = '79px';
    expandedMainSidebarWidth = '316px';

    expandedMainWithSecondarySidebarWidth = '553px';
    collapsedMainWithSecondarySidebarWidth = '316px';

    private sidebarExpandedSubject = new BehaviorSubject<boolean>(true);
    sidebarExpanded$: Observable<boolean> = this.sidebarExpandedSubject.asObservable();

    private moduleDataSubject = new BehaviorSubject<ModuleItem | null>(null);
    moduleData$: Observable<ModuleItem | null> = this.moduleDataSubject.asObservable();

    constructor(private router: Router) {
        this.initializeModuleDataSubscription();
    }

    get isExpanded(): boolean {
        return !this.sidebarExpandedSubject.getValue();
    }

    toggleSidebar(): void {
        this.sidebarExpandedSubject.next(!this.sidebarExpandedSubject.getValue());
    }

    setSidebarState(isExpanded: boolean): void {
        this.sidebarExpandedSubject.next(isExpanded);
    }

    private initializeModuleDataSubscription(): void {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
            const fullPath = this.router.url.split('?')[0];
            const currentPath = fullPath.split('/')[1];
            const module = TenantModules.find(module => module.id === currentPath) || null;
            this.moduleDataSubject.next(module);
        });
    }
}
