import { Component, inject } from '@angular/core';
import { SidebarStateService } from '../../services/sidebar.service';

@Component({
    selector: 'app-secondary-sidebar',
    templateUrl: './secondary-sidebar.component.html',
})
export class SecondarySidebarComponent {
    sidebarStateService = inject(SidebarStateService);

    moduleData$ = this.sidebarStateService.moduleData$;
    isExpanded$ = this.sidebarStateService.sidebarExpanded$;
}
