<div class="px-6 flex overflow-hidden">
    <a
        class="flex items-center gap-3 text-black w-full"
        nz-dropdown
        [nzVisible]="isActive"
        nzTrigger="click"
        (nzVisibleChange)="isActive ? (isActive = false) : (isActive = true)"
        [nzDropdownMenu]="userDropdownOptions"
    >
        <i
            class="inline-flex box-content justify-center items-center text-[1.125rem] min-w-8 min-h-8 rounded-full bg-black/25 text-white"
            nz-icon
            nzType="user"
            nzTheme="outline"
        ></i>
        <span class="flex gap-3" *ngIf="!isExpanded">
            <span class="line-clamp-1"> {{ displayName }}</span>
            <i
                nz-icon
                nzType="down"
                nzTheme="outline"
                class="transition duration-200"
                [ngClass]="{ 'rotate-180': isActive, 'rotate-0': !isActive }"
            ></i>
        </span>
    </a>
</div>
<nz-dropdown-menu #userDropdownOptions>
    <div
        class="flex flex-col items-stretch mx-3 *:text-left *:py-1.5 *:px-3 *:transition *:rounded-lg rounded-lg shadow-lg p-1 bg-white"
    >
        <button nz-button nzType="text" class="flex h-full hover:bg-black/[6%]">
            <a routerLink="/profile" class="flex">{{ 'My profile' | translate }}</a>
        </button>
        <button class="text-alert hover:bg-alert hover:text-white" (click)="logout()" (keyup)="logout()">
            {{ 'Sign out' | translate }}
        </button>
    </div>
</nz-dropdown-menu>
