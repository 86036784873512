import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../../../services/auth/auth.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnDestroy {
    @Input() isExpanded: boolean;
    @Input() displayName: string;
    isActive: boolean = false;

    private readonly authService = inject(AuthService);
    private logoutSubscription: Subscription;

    logout() {
        this.logoutSubscription = this.authService.logout().subscribe();
    }

    ngOnDestroy(): void {
        if (this.logoutSubscription) {
            this.logoutSubscription.unsubscribe();
        }
    }
}
