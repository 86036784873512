<div class="min-h-[3.5rem]">
    <header
        *ngIf=" moduleData$ | async as moduleData"
        [class.hidden]="moduleData.direction !== 'horizontal'"
        [ngStyle]="{ 'width': isExpanded ? 'calc(100% - ' + sidebarCollapsed + ')' : 'calc(100% - ' + sidebarExpanded + ')' }"
        class="fixed transition-all top-0 flex justify-between items-center min-h-[3.5rem] w-full py-3 px-6 row-span-1 col-span-2 bg-white z-[9999]"
    >
        <div class="module-details">
            <p class="flex items-center gap-2">
                <span
                    class="flex w-fit empty:hidden text-primary-green bg-primary-green/10 p-1.5 rounded-full"
                    nz-icon
                    [nzType]="moduleData?.icon"
                ></span>
                <span class="text-bd-sm-700">{{moduleData?.name || 'Alternative text'}}</span>
            </p>
        </div>
        <div>
            <ul class="module-navigation-links flex items-center">
                @for(submodule of moduleData?.submodules; track submodule) {
                <li>
                    <a
                        [routerLink]="submodule.href"
                        [queryParams]="submodule.params"
                        [routerLinkActive]="['navigation-item-active']"
                        class="navigation-item"
                        >{{submodule?.title}}</a
                    >
                </li>
                }
            </ul>
        </div>
    </header>
</div>
