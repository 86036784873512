import { Component, OnInit } from '@angular/core';
import { AuthService } from './core/services/auth/auth.service';
import { AppState } from './core/state';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { AuthActions } from './core/state/auth.actions';
import { AuthSelectors } from './core/state/auth.selectors';
import { User } from './core/models/user';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.css',
})
export class AppComponent implements OnInit {
    title = 'aios-angular';

    isAuthenticated: boolean;
    /**
     * Current user observable.
     */
    user$: Observable<User>;

    subscriptions = new Subscription();

    constructor(
        private translate: TranslateService,
        private readonly store$: Store<AppState>,
        private readonly authService: AuthService
    ) {
        this.translate.addLangs(['pl', 'en']);
        this.translate.setDefaultLang('pl');
        this.translate.use('pl');
    }

    ngOnInit(): void {
        this.store$.dispatch(AuthActions.initAuth());

        this.user$ = this.store$.select(AuthSelectors.user);

        this.subscriptions.add(
            this.store$.select(AuthSelectors.isAuthenticated).subscribe(is => {
                this.isAuthenticated = is;

                if (is) {
                    console.log('isAuthneticated');
                } else {
                    console.log('unauthenticated');
                }
            })
        );
    }

    onLogout() {
        this.authService.logout().subscribe();
    }
    useLanguage(language: string): void {
        this.translate.use(language);
    }
}
