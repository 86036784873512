import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

/**
 * Component displayed when navigating to an non existing page.
 */
@Component({
    selector: 'app-page-not-found',
    templateUrl: './not-found-page.component.html',
})
export class NotFoundPageComponent implements OnInit {
    constructor(private readonly titleService: Title) {}

    /**
     * @inheritDoc
     */
    ngOnInit(): void {
        this.titleService.setTitle('Not found | aiOS');
    }
}
