<ul
    nz-menu
    nzMode="inline"
    class="border-x-0 py-4 transition-all duration-200"
    [ngClass]="isExpanded ? ' pl-1.5 pr-2': ''"
>
    <li nz-menu-item class="logo-container !my-0 flex h-full !bg-transparent !px-0 !py-0 after:hidden">
        <a routerLink="/" class="ml-1 flex w-[128px] justify-start before:hidden">
            <img class="logo" src="assets/aiconic-logo.svg" alt="AICONIC logo" />
        </a>
    </li>
</ul>
<!--<app-general-search [isExpanded]="isExpanded" />-->
