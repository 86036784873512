import { ModuleItem, ModuleList } from './types';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';

export const TenantModules: ModuleItem[] = [
    {
        id: 'aimarketing',
        name: _('Ai Marketing'),
        href: '/aimarketing',
        isFavorite: false,
        icon: 'hdd',
        direction: 'vertical',
        submodules: [
            {
                title: _('AiMarketing.salesHistory'),
                href: '/aimarketing/saleshistory',
            },
            {
                title: _('AiMarketing.budgetSummary'),
                href: '/aimarketing/budgetsummary',
            },
        ],
    },
    {
        id: 'rfx',
        name: _('AI RFX Automation'),
        href: '/rfx',
        isFavorite: false,
        icon: 'bug',
        direction: 'horizontal',
        submodules: [
            {
                title: 'RFX List',
                href: '/rfx',
                params: {
                    sortDirection: 'DESC',
                    sortBy: 'createdAt',
                },
            },
        ],
    },
    {
        id: 'demandforecasting',
        name: _('AI Demand  Forecasting'),
        href: '/demandforecasting',
        isFavorite: true,
        icon: 'fund',
        direction: 'horizontal',
        submodules: [
            {
                title: _('Dashboard'),
                href: '/demandforecasting/dashboard',
            },
            {
                title: _('Planning'),
                href: '/demandforecasting/planning/client',
            },
            {
                title: _('KPI'),
                href: '/demandforecasting/kpi',
            },
            {
                title: _('Product'),
                href: '/demandforecasting/products',
            },
            {
                title: _('Clients'),
                href: '/demandforecasting/clients',
            },
            {
                title: _('Forecast'),
                href: '/demandforecasting/forecast',
            },
            {
                title: _('Data'),
                href: '/demandforecasting/data-upload',
            },
        ],
    },
];

export const BaseModuleList: ModuleList[] = [
    {
        blockTitle: _('General'),
        modules: [
            {
                name: _('Main Board'),
                href: '/dashboard',
                icon: 'desktop',
            },
            {
                name: _('Notifications'),
                href: '/notifcations',
                icon: 'notification',
            },
        ],
    },
    {
        blockTitle: _('Modules'),
        modules: [...TenantModules],
    },
    // {
    //     blockTitle: 'Ulubione',
    //     modules: [...AllModules.filter(module => module.isFavorite)],
    // },
];

export const SupportAndSettingsModuleList: ModuleList[] = [
    {
        blockTitle: _('Administration'),
        modules: [
            {
                name: _('Support'),
                href: '/support',
                icon: 'info-circle',
            },
            {
                name: _('Settings'),
                href: '/settings',
                icon: 'setting',
            },
        ],
    },
];
export const LanguageSwitcherModuleItem: ModuleItem = {
    name: 'LanguageSwitcher',
    icon: 'flag',
};
