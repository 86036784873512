import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard, unauthorizedGuard } from './core/auth-guards/auth.guard';
import { NotFoundPageComponent } from './core/pages/not-found-page/not-found-page.component';
import { userPermissionGuard } from './core/user-permission-guards/user-permission.guard';

const routes: Routes = [
    // Home page route
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard',
    },
    {
        path: 'signin',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
        canActivate: [unauthorizedGuard],
        canActivateChild: [unauthorizedGuard],
    },
    // Business modules routes
    {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [authGuard],
        canActivateChild: [],
    },
    {
        path: 'notifications',
        loadChildren: () => import('./modules/notifications/notifications.module').then(m => m.NotificationsModule),
        canActivate: [authGuard],
        canActivateChild: [],
    },
    {
        path: 'settings',
        loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule),
        canActivate: [authGuard],
    },
    {
        path: 'rfx',
        loadChildren: () => import('./modules/rfx/rfx.module').then(m => m.RfxModule),
        canActivate: [authGuard, userPermissionGuard],
    },
    {
        path: 'demandforecasting',
        loadChildren: () =>
            import('./modules/demand-forecasting/demand-forecasting.module').then(m => m.DemandForecastingModule),
        canActivate: [authGuard, userPermissionGuard],
    },
    {
        path: 'aimarketing',
        loadChildren: () => import('./modules/aimarketing/aimarketing.module').then(m => m.AiMarketingModule),
        canActivate: [authGuard, userPermissionGuard],
    },
    {
        path: 'aicontract',
        loadChildren: () => import('./modules/aicontract/aicontract.module').then(m => m.AiContractModule),
        canActivate: [authGuard, userPermissionGuard],
    },
    { path: 'not-found', component: NotFoundPageComponent },
    { path: '**', redirectTo: 'not-found' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            onSameUrlNavigation: 'reload',
            bindToComponentInputs: true,
            scrollPositionRestoration: 'enabled',
            enableViewTransitions: true,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
