@if (!isExpanded) {
<div class="search-expanded py-4 px-6 my-1.5">
    <nz-input-group class="rounded-lg" nzSearch [nzAddOnAfter]="suffixIconButton">
        <input type="text" nz-input [placeholder]="'Search' | translate" class="rounded-lg" />
        <ng-template #suffixIconButton>
            <button nz-button nzType="default" class="!rounded-ee-lg rounded-l-none !rounded-tr-lg group">
                <span nz-icon nzType="search"></span>
            </button>
        </ng-template>
    </nz-input-group>
</div>
} @else {
<div class="search-collapsed flex justify-center w-full !p-4 !my-1.5 mx-auto">
    <button
        class="btn-search inline-flex justify-center items-center mx-auto"
        nz-button
        nzType="primary"
        nzShape="circle"
        (click)="search()"
    >
        <span nz-icon nzType="search" class="text-white size-4"></span>
    </button>
</div>
}
