<div class="flex flex-col">
    <a
        class="my-4 flex w-full items-center gap-3 text-black"
        nz-dropdown
        [nzVisible]="isActive"
        nzTrigger="click"
        (nzVisibleChange)="isActive ? (isActive = false) : (isActive = true)"
        [nzDropdownMenu]="userDropdownOptions"
    >
        <i
            class="box-content inline-flex min-h-8 min-w-8 items-center justify-center rounded-full bg-black/25 text-[1.125rem] text-white"
            nz-icon
            nzType="user"
            nzTheme="outline"
            [ngClass]="isExpanded ? 'mx-2': 'mx-0'"
            [nzTooltipTitle]="isExpanded ? ('My profile' | translate) : null"
            nzTooltipPlacement="right"
            nz-button
            nz-tooltip
        ></i>
        <span class="flex w-[85%] justify-between gap-3" *ngIf="!isExpanded">
            <span class="truncate"> {{ displayName }}</span>
            <i
                nz-icon
                nzType="down"
                nzTheme="outline"
                class="transition duration-200"
                [ngClass]="{ 'rotate-180': isActive, 'rotate-0': !isActive }"
            ></i>
        </span>
    </a>
    <a class="mb-4 flex w-full items-center text-black" routerLink="/support">
        <i
            class="inline-flex items-center justify-center text-[32px] text-black/25"
            nz-icon
            nzType="question-circle"
            nzTheme="fill"
            [ngClass]="isExpanded ? 'mx-2': 'mx-0'"
            [nzTooltipTitle]="isExpanded ? ('Support' | translate) : null"
            nzTooltipPlacement="right"
            nz-button
            nz-tooltip
        ></i>
        <span class="ml-3 flex w-[85%] gap-3" *ngIf="!isExpanded">
            <span class="truncate"> {{ 'Support' | translate }} </span>
        </span>
    </a>
</div>

<nz-dropdown-menu #userDropdownOptions>
    <div
        class="mx-3 flex flex-col items-stretch rounded-lg bg-white p-1 shadow-lg *:rounded-lg *:px-3 *:py-1.5 *:text-left *:transition"
    >
        <button nz-button nzType="text" class="flex h-full items-center hover:bg-black/[6%]">
            <i
                nz-icon
                nzType="question-circle"
                nzTheme="fill"
                class="mr-2 transition duration-200"
                [ngClass]="isExpanded ? 'mx-2': 'mx-0'"
            ></i>
            <a routerLink="/profile" class="flex">{{ 'My profile' | translate }}</a>
        </button>
        <button nz-button nzType="text" class="flex h-full items-center hover:bg-black/[6%]">
            <i
                nz-icon
                nzType="setting"
                nzTheme="outline"
                class="mr-2 transition duration-200"
                [ngClass]="isExpanded ? 'mx-2': 'mx-0'"
            ></i>
            <a routerLink="/settings" class="flex">{{ 'Settings' | translate }}</a>
        </button>

        <div class="flex w-full">
            <nz-form-label class="w-2/5"> {{'Language' | translate}} </nz-form-label>
            <nz-select [(ngModel)]="selectedLang" (ngModelChange)="langChange($event)" class="w-3/5">
                <nz-option nzValue="pl" nzLabel="{{ 'polish' | translate }}"></nz-option>
                <nz-option nzValue="en" nzLabel="{{ 'english' | translate }}"></nz-option>
            </nz-select>
        </div>

        <button class="text-alert hover:bg-alert hover:text-white" (click)="logout()" (keyup)="logout()">
            <i
                nz-icon
                nzType="logout"
                nzTheme="outline"
                class="mr-2 transition duration-200"
                [ngClass]="isExpanded ? 'mx-2': 'mx-0'"
            ></i>
            {{ 'Sign out' | translate }}
        </button>
    </div>
</nz-dropdown-menu>
