import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard, unauthorizedGuard } from './core/auth-guards/auth.guard';
import { NotFoundPageComponent } from './core/pages/not-found-page/not-found-page.component';

const routes: Routes = [
    // Home page route
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard',
    },
    {
        path: 'signin',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
        canActivate: [unauthorizedGuard],
        canActivateChild: [unauthorizedGuard],
    },
    // Business modules routes
    {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [authGuard],
        canActivateChild: [],
    },
    {
        path: 'rfx',
        loadChildren: () => import('./modules/rfx/rfx.module').then(m => m.RfxModule),
        canActivate: [authGuard],
    },
    {
        path: 'demandforecasting',
        loadChildren: () =>
            import('./modules/demand-forecasting/demand-forecasting.module').then(m => m.DemandForecastingModule),
        canActivate: [authGuard],
    },
    { path: '**', component: NotFoundPageComponent, canActivate: [authGuard] },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            onSameUrlNavigation: 'reload',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
