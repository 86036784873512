import { NgModule, LOCALE_ID, inject } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
    HTTP_INTERCEPTORS,
    HttpClient,
    HttpClientModule,
    provideHttpClient,
    withInterceptorsFromDi,
} from '@angular/common/http';
import { metaReducers, reducers } from './core/state';
import { AppEffects } from './core/state/app.effects';
import { AuthEffects } from './core/state/auth.effects';
import { EnvironmentConfig } from './core/environment-config';
import { environment } from '../environments/environment';
import { SubdomainInterceptor } from './core/interceptors/subdomain.interceptor';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import pl from '@angular/common/locales/pl';
import { en_US, pl_PL, NZ_I18N } from 'ng-zorro-antd/i18n';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
registerLocaleData(en);
registerLocaleData(pl);

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        AppRoutingModule,
        HttpClientModule,
        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
            },
        }),
        EffectsModule.forRoot([AppEffects, AuthEffects]),
        CoreModule,
    ],
    providers: [
        { provide: EnvironmentConfig, useFactory: () => environment },
        { provide: HTTP_INTERCEPTORS, useClass: SubdomainInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        {
            provide: NZ_I18N,
            useFactory: () => {
                const localId = inject(LOCALE_ID);
                switch (localId) {
                    case 'en':
                        return en_US;
                    case 'pl':
                        return pl_PL;
                    default:
                        return getLanguage();
                }
            },
        },
        provideAnimationsAsync(),
        provideHttpClient(),
        provideHttpClient(withInterceptorsFromDi()),
    ],

    bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

function getLanguage() {
    const selectedLang = localStorage.getItem('lang');
    if (selectedLang === 'pl' || !selectedLang) return pl_PL;
    if (selectedLang === 'en') return en_US;
    return false;
}
