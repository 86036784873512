import { ChangeDetectorRef, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AppState } from '../../state';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { BaseModuleList, SupportAndSettingsModuleList } from './module-list';
import { selectAuthState } from '../../state/auth.selectors';
import { AuthState } from '../../state/auth.reducers';
import { SidebarStateService } from '../../services/sidebar.service';
import { ModuleItem } from './types';

/**
 * Main sidebar component.
 */
@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styles: `
        .ant-menu-item {
            padding: 0 !important;
            height: auto !important;
        }
    `,
})
export class SidebarComponent implements OnInit, OnDestroy {
    public availableModuleList = [];
    public supportAndSettingsModuleList = SupportAndSettingsModuleList;
    public displayName: string;

    private subscriptions = new Subscription();
    public sidebarStateService = inject(SidebarStateService);

    private readonly router = inject(Router);
    private readonly store$ = inject(Store<AppState>);
    private readonly cdr = inject(ChangeDetectorRef);

    private moduleData: ModuleItem | null = null;
    public enableTransition: boolean = true;
    public isExpanded: boolean = this.sidebarStateService.isExpanded;
    public marginRight: string = this.sidebarStateService.expandedMainSidebarWidth;

    private adjustMargin(): void {
        const direction = this.moduleData?.direction || 'horizontal';

        if (direction === 'vertical') {
            this.marginRight = !this.isExpanded
                ? this.sidebarStateService.expandedMainWithSecondarySidebarWidth
                : this.sidebarStateService.collapsedMainWithSecondarySidebarWidth;
        } else {
            this.marginRight = !this.isExpanded
                ? this.sidebarStateService.expandedMainSidebarWidth
                : this.sidebarStateService.collapsedMainSidebarWidth;
        }
    }

    ngOnInit(): void {
        // when we change the page animation should be initially disabled and then enabled so we don't have weird layout transitions
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.enableTransition = false;
            } else if (event instanceof NavigationEnd) {
                setTimeout(() => (this.enableTransition = true), 100);
            }
        });
        // adjust margin depending on the direction in the module
        this.sidebarStateService.moduleData$.subscribe(module => {
            this.moduleData = module;
            this.adjustMargin();
        });

        // adjust margin for collapsed/expanded state of the sidebar
        this.sidebarStateService.sidebarExpanded$.subscribe(() => {
            this.adjustMargin();
        });

        this.subscriptions.add(
            this.store$.select(selectAuthState).subscribe(state => {
                if (state?.user?.displayName) {
                    this.displayName = state?.user?.displayName;
                }
                this.updateMenu(state);
            })
        );
    }
    onCollapsedChange(isCollapsed: boolean): void {
        this.sidebarStateService.setSidebarState(!isCollapsed);
        this.cdr.detectChanges();
    }
    generalSearch() {}

    updateMenu(state: AuthState): void {
        const userModules = state?.user?.modules;
        this.availableModuleList = BaseModuleList.map(block => {
            const filteredModules = block.modules.filter(module => {
                return module.id ? userModules.includes(module.id) : true;
            });

            // Return a new block with filtered modules
            return {
                blockTitle: block.blockTitle,
                modules: filteredModules,
            };
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
