import { Component, inject, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AppState } from '../../state';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AvailableModuleList, SupportAndSettingsModuleList } from './module-list';
import { selectAuthState } from '../../state/auth.selectors';

/**
 * Main sidebar component.
 */
@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styles: `
        .ant-menu-item {
            padding: 0 !important;
            height: auto !important;
        }
    `,
})
export class SidebarComponent implements OnInit, OnDestroy {
    subscriptions = new Subscription();
    availableModuleList = AvailableModuleList;
    supportAndSettingsModuleList = SupportAndSettingsModuleList;
    displayName: string;

    private readonly router = inject(Router);
    private readonly store$ = inject(Store<AppState>);

    isExpanded: boolean;

    constructor() {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.store$.select(selectAuthState).subscribe(state => {
                if (state?.user?.displayName) {
                    this.displayName = state?.user?.displayName;
                }
                //FIXME: update menu depending what modules users see
            })
        );
    }
    generalSearch() {}

    toggleSidebar(): void {
        this.isExpanded = !this.isExpanded;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
