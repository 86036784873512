import { ModuleItem, ModuleList } from './types';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';

export const TenantModules: ModuleItem[] = [
    {
        id: 'dashboard',
        name: _('AI RFX Automation'),
        href: '/dashboard',
        isFavorite: false,
        icon: 'bug',
    },
    {
        id: 'settings',
        name: 'SettingsModule.mainTitle',
        href: '/settings',
        isFavorite: false,
        icon: 'setting',
        direction: 'vertical',
        submodules: [
            {
                title: _('settings.profile'),
                href: '/settings/profile',
            },
            {
                title: _('settings.systemsNotifications'),
                href: '/settings/systems-notifications',
            },
            {
                title: _('settings.groups'),
                href: '/settings/groups',
            },
            {
                title: _('SettingsModule.resetPassword'),
                href: '/settings/reset-password',
            },
        ],
    },
    {
        id: 'aimarketing',
        name: _('Ai Marketing'),
        href: '/aimarketing/sales-history',
        isFavorite: false,
        icon: 'hdd',
        direction: 'vertical',
        submodules: [
            {
                title: _('AiMarketing.salesHistory'),
                href: '/aimarketing/sales-history',
            },
            {
                title: _('AiMarketing.budgetSummary'),
                href: '/aimarketing/budget-summary',
            },
            {
                title: _('AiMarketing.expanditureAnalysis'),
                href: '/aimarketing/expanditure-analysis',
            },
            {
                title: _('AiMarketing.mediaAttribute'),
                href: '/aimarketing/media-attribute',
            },
            {
                title: _('AiMarketing.summaryManagement'),
                href: '/aimarketing/management-summary',
            },
            {
                title: _('AiMarketing.productSummary'),
                href: '/aimarketing/product-summary',
            },
        ],
    },
    {
        id: 'rfx',
        name: _('AI RFX Automation'),
        href: '/rfx',
        isFavorite: false,
        icon: 'bug',
        direction: 'horizontal',
        submodules: [
            {
                title: 'RFX List',
                href: '/rfx',
                params: {
                    sortDirection: 'DESC',
                    sortBy: 'createdAt',
                },
            },
        ],
    },
    {
        id: 'demandforecasting',
        name: _('AI Demand  Forecasting'),
        href: '/demandforecasting/planning',
        isFavorite: true,
        icon: 'fund',
        direction: 'horizontal',
        submodules: [
            {
                title: _('DemandForecasting.planningMenu'),
                href: '/demandforecasting/planning',
            },
            // {
            //     title: _('DemandForecasting.KPIMenu'),
            //     href: '/demandforecasting/kpi',
            // },
            {
                title: _('DemandForecasting.productMenu'),
                href: '/demandforecasting/products',
            },
            {
                title: _('DemandForecasting.clientsMenu'),
                href: '/demandforecasting/clients',
            },
            {
                title: _('DemandForecasting.forecastMenu'),
                href: '/demandforecasting/forecast',
            },
            {
                title: _('DemandForecasting.dataMenu'),
                href: '/demandforecasting/data-upload',
            },
        ],
    },
    {
        id: 'aicontract',
        name: _('AI Contract Intelligence'),
        href: '/aicontract',
        isFavorite: false,
        icon: 'bug',
        direction: 'horizontal',
        submodules: [
            {
                title: _('AiContract.search'),
                href: '/aicontract/search',
            },
            {
                title: _('AiContract.dashboard'),
                href: '/aicontract/dashboard',
            },
            {
                title: _('AiContract.contractAi'),
                href: '/aicontract/ai-contract',
            },
        ],
    },
];

export const BaseModuleList: ModuleList[] = [
    {
        blockTitle: _('General'),
        modules: [
            {
                name: _('Main Board'),
                href: '/dashboard',
                icon: 'desktop',
            },
            {
                name: 'notifications.notifications',
                href: '/notifications',
                icon: 'bell',
            },
        ],
    },
    {
        blockTitle: _('Modules'),
        modules: [...TenantModules],
    },
    // {
    //     blockTitle: 'Ulubione',
    //     modules: [...AllModules.filter(module => module.isFavorite)],
    // },
];

export const LanguageSwitcherModuleItem: ModuleItem = {
    name: 'LanguageSwitcher',
    icon: 'flag',
};
