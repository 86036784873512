import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ModuleItem } from '../../types';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
    selector: '[app-sidebar-block]',
    templateUrl: './sidebar-block.component.html',
})
export class SidebarBlockComponent implements OnInit, OnDestroy {
    @Input() moduleItem: ModuleItem;
    @Input() isExpanded: boolean;

    isActive: boolean = false;
    private routerSubscription: Subscription;

    constructor(private router: Router) {}

    ngOnInit(): void {
        this.checkIfActive();

        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(() => {
                this.checkIfActive();
            });
    }

    ngOnDestroy(): void {
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }
    }

    private checkIfActive(): void {
        const currentPath = this.router.url;
        this.isActive = currentPath === this.moduleItem.href;
    }

    handleClick(): void {
        this.isActive = true;
    }
}
