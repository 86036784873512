<div class="top-bar">
    <ul nz-menu nzMode="inline" class="p-4">
        <li nz-menu-item class="logo-container flex h-full px-0 !my-0 !py-0">
            <a href="/" class="">
                <img class="logo flex gap-0 max-w-none w-[128px]" src="assets/aiconic-logo.svg" alt="AICONIC logo" />
            </a>
        </li>
    </ul>
    <app-general-search [isExpanded]="isExpanded" />
</div>
