import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { authReducer, AuthState } from './auth.reducers';
import { environment } from '../../../environments/environment';

/**
 * Base state interface.
 */
export interface AppState {
    /**
     * Authentication state.
     */
    auth: AuthState;
}

/**
 * NgRx state reducers.
 */
export const reducers: ActionReducerMap<AppState> = {
    auth: authReducer,
};

/**
 * Meta reducers.
 */
export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];
