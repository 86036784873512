import { Component, inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-page-not-found',
    templateUrl: './not-found-page.component.html',
})
export class NotFoundPageComponent implements OnInit {
    private readonly titleService = inject(Title);
    private readonly translationService = inject(TranslateService);
    
    ngOnInit(): void {
        this.titleService.setTitle(this.translationService.instant('Not Found Page') + ' | AICONIC');
    }
}
