<div
    [ngClass]="{ 'transition-all': enableTransition, 'transition-none': !enableTransition }"
    [ngStyle]="{ 'margin-right': marginRight }"
>
    <div class="fixed flex h-full bg-white">
        <nz-layout
            class="sidebar relative z-[10] transition-all duration-200 bg-transparent h-full"
            [class.w-[316px]]="!isExpanded"
            [class.w-[79px]]="isExpanded"
        >
            <nz-sider
                class="bg-white flex flex-col justify-between h-full"
                nzCollapsible
                [(nzCollapsed)]="isExpanded"
                nzTheme="light"
                nzWidth="316px"
                [nzCollapsedWidth]="79"
                (nzCollapsedChange)="onCollapsedChange($event)"
                [nzTrigger]="customTrigger"
            >
                <div class="main-content flex flex-col justify-between h-full">
                    <div>
                        <app-sidebar-topbar [isExpanded]="isExpanded" />
                        <app-sidebar-section [moduleList]="availableModuleList" [isExpanded]="isExpanded" />
                    </div>
                    <div>
                        <app-user-profile [isExpanded]="isExpanded" [displayName]="displayName" />
                        <app-sidebar-section [moduleList]="supportAndSettingsModuleList" [isExpanded]="isExpanded" />
                    </div>
                </div>
                <ng-template #customTrigger>
                    <div class="pt-2 px-8" *ngIf="!isExpanded">
                        <button
                            class="btn-hide flex justify-center items-center w-full rounded-lg overflow-clip"
                            nz-button
                            nzType="default"
                        >
                            <span nz-icon nzType="left" nzTheme="outline"></span> {{ !isExpanded ? ('Sidebar.hideMenu' |
                            translate) : null }}
                        </button>
                    </div>
                    <div class="pt-2 px-3" *ngIf="isExpanded">
                        <button
                            class="btn-hide flex justify-center items-center w-full rounded-lg overflow-clip"
                            nz-button
                            nzType="default"
                        >
                            <span nz-icon nzType="right" nzTheme="outline"></span>
                        </button>
                    </div>
                </ng-template>
            </nz-sider>
        </nz-layout>
        <app-secondary-sidebar></app-secondary-sidebar>
    </div>
</div>
