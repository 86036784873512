import { Component, inject, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TenantModules } from '../sidebar/module-list';
import { ModuleItem } from '../sidebar/types';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { SidebarStateService } from '../../services/sidebar.service';

@Component({
    selector: 'app-top-navigation',
    templateUrl: './top-navigation.component.html',
})
export class TopNavigationComponent implements OnInit {
    public sidebarStateService = inject(SidebarStateService);
    private router = inject(Router);
    private moduleDataSubject = new BehaviorSubject<ModuleItem | null>(null);
    moduleData$: Observable<ModuleItem | null> = this.moduleDataSubject.asObservable();

    get isExpanded(): boolean {
        return this.sidebarStateService.isExpanded;
    }
    get sidebarExpanded() {
        return this.sidebarStateService.expandedMainSidebarWidth;
    }
    get sidebarCollapsed() {
        return this.sidebarStateService.collapsedMainSidebarWidth;
    }

    ngOnInit(): void {
        this.router.events.subscribe(() => {
            const fullPath = this.router.url.split('?')[0];
            const currentPath = fullPath.split('/')[1];
            const module = TenantModules.find(module => module.id === currentPath) || null;
            this.moduleDataSubject.next(module);
        });
    }
}
