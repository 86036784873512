<nz-layout class="sidebar bg-transparent h-full">
    <nz-sider
        class="bg-white flex flex-col justify-between h-full"
        nzCollapsible
        [(nzCollapsed)]="isExpanded"
        nzTheme="light"
        nzWidth="316px"
        [nzCollapsedWidth]="79"
        [nzTrigger]="customTrigger"
    >
        <div class="main-content flex flex-col justify-between h-full">
            <div>
                <app-sidebar-topbar [isExpanded]="isExpanded" />
                <app-sidebar-section [moduleList]="availableModuleList" [isExpanded]="isExpanded" />
            </div>
            <div>
                <app-user-profile [isExpanded]="isExpanded" [displayName]="displayName" />
                <app-sidebar-section [moduleList]="supportAndSettingsModuleList" [isExpanded]="isExpanded" />
            </div>
        </div>
        <ng-template #customTrigger>
            <div class="pt-2 px-8" *ngIf="!isExpanded">
                <button
                    class="btn-hide flex justify-center items-center w-full rounded-lg overflow-clip"
                    nz-button
                    nzType="default"
                >
                    <span nz-icon nzType="left" nzTheme="outline"></span> {{ !isExpanded ? 'Ukryj menu' : null }}
                </button>
            </div>
            <div class="pt-2 px-3" *ngIf="isExpanded">
                <button
                    class="btn-hide flex justify-center items-center w-full rounded-lg overflow-clip"
                    nz-button
                    nzType="default"
                >
                    <span nz-icon nzType="right" nzTheme="outline"></span>
                </button>
            </div>
        </ng-template>
    </nz-sider>
</nz-layout>
