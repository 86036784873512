<a
    nz-button
    nzType="text"
    [routerLink]="moduleItem.href"
    [routerLinkActive]="['sidebar-item-active']"
    (click)="handleClick()"
    class="module-link line-clamp-1 flex h-[40px] items-center justify-start gap-2.5 rounded-lg pb-0 transition-colors hover:bg-black/[6%]"
    [ngClass]="{
        'justify-center max-w-[38px] max-h-[40px] size-10 !m-1': isExpanded,
    }"
    style="padding-block: 0.625rem !important"
    [ngStyle]="{ 'padding-block': !isExpanded ? '0.625rem !important' : '0.25rem' }"
    [nzTooltipTitle]="isExpanded ? (moduleItem.name | translate) : null"
    nzTooltipPlacement="right"
    nz-button
    nz-tooltip
>
    <span nz-icon [nzType]="moduleItem.icon" nzTheme="outline" class="block"></span>
    @if (!isExpanded) {
    <span class="truncate">{{ moduleItem.name | translate }}</span>
    }
</a>
