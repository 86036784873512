<div class="top-bar">
    <ul nz-menu nzMode="inline" class="py-4 px-6 border-x-0">
        <li nz-menu-item class="logo-container flex h-full !px-0 !my-0 !py-0">
            <a href="/" class="flex justify-start before:hidden ml-1 w-[128px]">
                <img
                    class="logo"
                    src="assets/aiconic-logo.svg"
                    alt="AICONIC logo"
                />
            </a>
        </li>
    </ul>
    <app-general-search [isExpanded]="isExpanded" />
</div>
