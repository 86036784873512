import { Injectable } from '@angular/core';

/**
 * Main effects class.
 */
@Injectable()
export class AppEffects {
    // constructor(private actions$: Actions) {
    // }
}
