<div *ngIf="moduleData$ | async as moduleData" class="relative h-full">
    <div
        *ngIf="moduleData.direction === 'vertical'"
        class="secondary-sidebar relative flex h-full w-[237px] flex-col bg-white p-6 pl-4 transition-[width] duration-200 overflow-x-clip"
        [class.w-[60px]]="(isExpanded$ | async)"
    >
        <div class="module-details">
            <p class="flex items-center gap-2">
                <span
                    class="flex w-fit rounded-full bg-primary-green/10 p-1.5 text-primary-green empty:hidden"
                    nz-icon
                    [nzType]="moduleData?.icon"
                ></span>
                <span class="text-bd-sm-700" *ngIf="!(isExpanded$ | async)"
                    >{{moduleData?.name | translate}}</span
                >
            </p>
        </div>
        <div class="mt-6" *ngIf="!(isExpanded$ | async)">
            <p class="mb-4 text-bd-xs-500 text-black/45">{{ 'Sidebar.moduleNavigation' | translate }}</p>
            <ul class="flex flex-col gap-y-1">
                @for(submodule of moduleData.submodules; track submodule){
                <li>
                    <a
                        nz-button
                        nzType="text"
                        [routerLink]="submodule.href"
                        [routerLinkActive]="['navigation-item-active']"
                        class="line-clamp-1 flex h-[40px] items-center justify-start gap-2.5 rounded-lg pb-0 transition"
                        style="padding-block: 0.625rem !important"
                    >
                        <span>{{ submodule.title | translate }}</span>
                    </a>
                </li>
                }
            </ul>
        </div>
        <div class="button-containter absolute bottom-[10px]" *ngIf="moduleData$ | async as moduleData">
            <div class="sidebar-state-toggle w-[205px]" *ngIf="!(isExpanded$ | async)">
                <button
                    class="btn-hide flex w-full items-center justify-center overflow-clip rounded-lg"
                    nz-button
                    nzType="default"
                    (click)="setExpanded()"
                >
                    <span nz-icon nzType="left" nzTheme="outline"></span> {{ !(isExpanded$ | async) ?
                    ('Sidebar.hideMenu' | translate) : null }}
                </button>
            </div>
            <div class="w-[30px]" *ngIf="isExpanded$ | async">
                <button
                    class="btn-hide flex w-full items-center justify-center overflow-clip rounded-lg"
                    nz-button
                    nzType="default"
                    (click)="setExpanded()"
                >
                    <span nz-icon nzType="right" nzTheme="outline"></span>
                </button>
            </div>
        </div>
    </div>
</div>
