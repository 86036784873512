<div
    *ngIf="moduleData$ | async as moduleData"
    [class.hidden]="moduleData.direction !== 'vertical'"
    class="secondary-sidebar relative left-0 transition-all duration-200 bg-white border-x h-full p-6 pl-4 w-[237px]"
>
    <div class="module-details">
        <p class="flex items-center gap-2">
            <span
                class="flex w-fit empty:hidden text-primary-green bg-primary-green/10 p-1.5 rounded-full"
                nz-icon
                [nzType]="moduleData?.icon"
            ></span>
            <span class="text-bd-sm-700">{{moduleData?.name || 'Alternative text'}}</span>
        </p>
    </div>
    <div class="mt-6">
        <p class="text-bd-xs-500 mb-4 text-black/45">{{ 'Sidebar.moduleNavigation' | translate }}</p>
        <ul class="flex flex-col gap-y-1">
            @for(submodule of moduleData.submodules; track submodule){
            <li>
                <a
                    nz-button
                    nzType="text"
                    [routerLink]="submodule.href"
                    [routerLinkActive]="['navigation-item-active']"
                    class="flex justify-start items-center gap-2.5 h-[40px] pb-0 transition rounded-lg line-clamp-1"
                    style="padding-block: 0.625rem !important"
                >
                    <span>{{ submodule.title | translate }}</span>
                </a>
            </li>
            }
        </ul>
    </div>
</div>
