import { CanActivateFn, Router } from '@angular/router';
import { of } from 'rxjs';
import { selectAuthState } from '../state/auth.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DestroyRef, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../state';

export const userPermissionGuard: CanActivateFn = (route) => {
    const router = inject(Router);
    const store$ = inject(Store<AppState>);
    const destroyRef = inject(DestroyRef);
    const currentPath = route.routeConfig.path;

    store$
        .select(selectAuthState)
        .pipe(takeUntilDestroyed(destroyRef))
        .subscribe(state => {
            const supportedModules = state.user.modules;

            if (supportedModules.length && !supportedModules.includes(currentPath)) {
                router.navigate(['/not-found']);
            }
        });
    return of(true);
};
