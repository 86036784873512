<div class="relative flex w-full h-full">
    @if (isAuthenticated) {
    <app-sidebar></app-sidebar>
    }

    <div class="main-page-content basis-full h-full">
        <app-top-navigation></app-top-navigation>
        <div class="p-6">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
