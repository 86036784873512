<div
    class="flex h-screen w-full flex-col items-center justify-center space-x-8 space-y-16 lg:flex-row lg:space-y-0 2xl:space-x-0"
>
    <div class="xl:px-0 flex w-full flex-col items-center justify-center text-center lg:w-1/2 lg:px-2">
        <p class="text-7xl font-bold tracking-wider text-gray-300 md:text-8xl lg:text-9xl">404</p>
        <p class="mt-6 text-4xl font-bold tracking-wider text-gray-300 md:text-5xl lg:text-6xl">
            {{ 'Not Found Page' | translate }}
        </p>

        <button
            class="flex items-center disabled:!bg-[#F5F5F5] disabled:!text-[#d9d9d9] disabled:hover:!text-[#d9d9d9] mt-10"
            nz-button
            nzType="primary"
            nzSize="large"
            routerLink="/"
        >
            {{ 'HomePage' | translate }}
        </button>
    </div>
</div>
