import { Component, Input } from '@angular/core';
import { ModuleItem, ModuleList } from '../../types';

@Component({
    selector: 'app-sidebar-section',
    templateUrl: './sidebar-section.component.html',
})
export class SidebarSectionComponent {
    @Input() moduleList: ModuleList[];
    @Input() isExpanded;

    trackByBlockTitle(index: number, block: ModuleList): string {
        return block.blockTitle;
    }

    trackByModuleName(index: number, module: ModuleItem): string {
        return module.name;
    }
}
